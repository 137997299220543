.Tag-Selected {
  border: 5px solid lightblue;
}
.Tag-Deselected {
  border: 5px solid white;
}

.Tag a,
.Tag a:hover,
.Tag a:active,
.Tag a:link,
.Tag a:visited {
  color: white;
  text-decoration: none;
}
